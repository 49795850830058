


$( document ).ready(function() {

    $(".js-count").spinner({
        min: 1000,
        max: 2500,
        start: 1000
    });

    if ($('#map').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map", {
                center: [47.244552, 39.843912],
                zoom: 16
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: './images/common/marker.png',
                iconImageSize: [50, 60],
                iconImageOffset: [-25, -60]
            });

            myMap.controls.remove('zoomControl');
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };

        ymaps.ready(init);
    }

    if ($('#map2').length) {
        var init = function init() {
            var myMap = new ymaps.Map("map2", {
                center: [47.244552, 39.843912],
                zoom: 16
            });

            var myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
                iconLayout: 'default#image',
                iconImageHref: '../images/common/marker.png',
                iconImageSize: [50, 60],
                iconImageOffset: [-25, -60]
            });

            myMap.controls.remove('zoomControl');
            myMap.behaviors.disable('scrollZoom');
            myMap.behaviors.disable('drag');
            myMap.geoObjects.add(myPlacemark);
        };

        ymaps.ready(init);
    }

    $(".js-tag-link").on("click" , function(){ 
        let heightStart = $(".js-tag-container").height(),
            heightEnd = $(".js-tag-wrap").height();
        console.log("heightStart = " , heightStart);

        $(this).toggleClass("is-active");

        if ($(this).hasClass("is-active")) {
            $(".js-tag-container").height(heightEnd);
            $(".js-tag-link").find("span").text("cкрыть");
        } else {
            $(".js-tag-container").removeAttr( 'style' );
            $(".js-tag-link").find("span").text("показать все");
        }
        
    });

    $(".js-catalog-mobile-btn").on("click" , function(){ 
        $(this).toggleClass("is-active");
        $("body").toggleClass("is-hidden");
        $(".js-mobile-catalog").toggleClass("is-active");
    });

    $(".js-hamburger").on("click" , function(){ 
        $(".js-mobile-menu").toggleClass("is-active");
    });

    $(".js-mobile-menu-close").on("click" , function(){ 
        $(".js-mobile-menu").toggleClass("is-active");
    });

    /*** TAB ****/
    $(".js-tab-item").on("click" , function(){ 
        let tabNumber = $(this).data("tab"),
            parents = $(this).parents(".js-tab");

        parents.find(".js-tab-item").removeClass("is-active");
        $(this).addClass("is-active");

        parents.find(".js-tab-block").removeClass("is-active");
        parents.find(".js-tab-block[data-tab="+tabNumber+"]").addClass("is-active");
        $(".js-case-slider-card").slick('refresh');
        $(".js-video-slider-card").slick('refresh');

        if ($(window).width() < 480) {
            $(".js-case-slider-card").slick('unslick');
        }

        if ($(window).width() < 480) {
            $(".js-video-slider-card").slick('unslick');
        }
    });
    /***  END TAB ****/

    /*** FAQ ***/
    $(".js-acc").on("click" , function(){ 

        $(this).addClass("is-active");
        $(this).next(".js-acc-body").slideToggle(300, function(){});
    });
    /*** END FAQ ***/

    $(".js-form-label").on("click", function (e) {
        $(this).parents(".js-form-field").addClass("is-active");
        $(this).parents(".js-form-field").find(".js-form-input").focus();
    });

    /*** POPUP ***/

    $(document).on('click','.js-popup-open',function(){
        var popup = $(this).data("popup");

        $("body").addClass("is-hidden");
        $(".js-popup[data-popup=" + popup + "]").addClass("is-open");
        return false;
    });

    $(document).on("click", function (e) {
        var popup = $(".js-popup");
        if (popup.is(e.target)) {
            $(popup).removeClass("is-open");
            $("body").removeClass("is-hidden");
        }
    });

    $(".js-popup-close").on("click", function () {
        $("body").removeClass("is-hidden");
        $(this).parents(".js-popup").removeClass("is-open");
        $(this).parents(".js-popup").removeClass("is-ok");
    });

    /*** END POPUP ***/



    if ($(window).width() > 960) {
        $(".js-aria-aside").stick_in_parent({
            offset_top: 350
        });

        $(".js-sticky-order").stick_in_parent({
            offset_top: 200
        });

        $(".js-map-aside").stick_in_parent({
            offset_top: 160
        });
    }


    /*** FILTER ***/
    $( ".js-range" ).slider({
        range: true,
        min: 0,
        max: 500,
        values: [ 75, 300 ],
        slide: function( event, ui ) {
          //$( "#amount" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
        }
    });

    $(".js-filter-btn").on("click", function(){
        $(".js-filter").addClass("is-open");
        $("body").addClass("is-hidden is-filter-open");
    });

    $(".js-filter-close").on("click", function(){
        $(".js-filter").removeClass("is-open");
        setTimeout(function () {
            $("body").removeClass("is-hidden is-filter-open");
        }, 400);
    });

    $(".js-filter").click(function () {
        $(this).removeClass("is-open");
        setTimeout(function () {
            $("body").removeClass("is-hidden is-filter-open");
        }, 400);
    }).children().click(function (e) {
        e.stopPropagation();
    });

    /*** END FILTER ***/



    $(".js-select").styler();


    /*** SLIDER ***/

    $(".js-client").slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>",
        responsive: [
            {
              breakpoint: 1440,

                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1141,
  
                  settings: {
                    slidesToShow: 5,
                      arrows: false
                  }
              }
        ]
    });

    if ($(window).width() < 961) {
        $(".js-client").slick('unslick');
    }

    $( window ).resize(function() {
        if ($(window).width() < 961) {
            $(".js-client").slick('unslick');
        }

        else {
            $(".js-client").slick({
                slidesToShow: 6,
                slidesToScroll: 1,
                infinite: true,
                arrows: true,
                prevArrow:"<button type='button' class='slick-prev pull-left'>\
                <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
                </button>",
                nextArrow:"<button type='button' class='slick-next pull-right'>\
                <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
                </button>",
                responsive: [
                    {
                      breakpoint: 1440,
        
                        settings: {
                            slidesToShow: 5
                        }
                    },
                    {
                        breakpoint: 1141,
          
                          settings: {
                            slidesToShow: 5,
                              arrows: false
                          }
                      }
                ]
            });
        }

        console.log($(window).width())
    });

    $(".js-card-slider").slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>"
    });

    $(".js-video-slider-card").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>"
    });

    if ($(window).width() < 480) {
        $(".js-video-slider-card").slick('unslick');
    }

    $(".js-case-slider-card").slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>"
    });

    if ($(window).width() < 480) {
        $(".js-case-slider-card").slick('unslick');
    }

    $(".js-case-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>",
        responsive: [
            {
              breakpoint: 1141,

                settings: {
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    });

    $('.js-slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>",
        asNavFor: '.js-slider-nav',
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    arrows: true
                }
            }
        ]
    });

    $('.js-slider-nav').slick({
        arrows: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        variableWidth: true,
        asNavFor: '.js-slider-for',
        focusOnSelect: true,
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    arrows: true
                }
            }
        ]
    });

    $(".js-inters-slider").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>",
        responsive: [
            {
              breakpoint: 1141,

                settings: {
                    slidesToShow: 3,
                    variableWidth: true,
                    arrows: false,
                    infinite: false
                }
            }
        ]
    });

    $(".js-prod-slider").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        prevArrow:"<button type='button' class='slick-prev pull-left'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-left'></use></svg>\
        </button>",
        nextArrow:"<button type='button' class='slick-next pull-right'>\
        <svg  width='11' height='18' viewBox='0 0 11 18' class='svg-icon'><use xlink:href='#svg-right'></use></svg>\
        </button>",
        responsive: [
            {
              breakpoint: 961,

                settings: {
                    variableWidth: true,
                    arrows: false,
                    infinite: false
                }
            }
        ]
    });

    if ($(window).width() < 960) {
        $(".js-prod-mobile").slick({
            variableWidth: true,
            arrows: false,
            infinite: false
        });
    }

});